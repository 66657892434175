var showClientCaseStudies = function () {
    $('#client-case-studies').addClass('selected');
    $('#individual-case-studies').removeClass('selected');
    $('.client-case-study').removeClass('hidden');
    $('.individual-case-study').addClass('hidden');
};

var showIndividualCaseStudies = function () {
    $('#individual-case-studies').addClass('selected');
    $('#client-case-studies').removeClass('selected');
    $('.individual-case-study').removeClass('hidden');
    $('.client-case-study').addClass('hidden');
};

var showAllCaseStudies = function () {
    $('#individual-case-studies').removeClass('selected');
    $('#client-case-studies').removeClass('selected');
    $('.individual-case-study').removeClass('hidden');
    $('.client-case-study').removeClass('hidden');
};

var pushAndPullCaseStudiesColumns = function () {
    $('.case-study-info-column').removeClass('order-md-2');
    $('.location-map').removeClass('order-md-1 black');
    var visibleElements = $('.case-study-row:not(.hidden)');
    visibleElements.each(function (index) {
        //silverstripe loops in the templates starts at 1 not 0, this makes the css match the template
        if ((Number(index + 1) % 2 === 0)) {
            $(this).find('.case-study-info-column').addClass('order-md-2');
            $(this).find('.location-map').addClass('order-md-1');
        } else {
            $(this).find('.location-map').addClass('black');
        }
    });
};

var animateProgressCircle = function (id) {
    var idString = '#' + id;
    var element = $(idString);
    var textColor = element.attr('data-text-color');
    var lineColor = element.attr('data-line-color');
    var percentage = element.attr('data-percentage') / 100;

    var bar = new ProgressBar.Circle(idString, {
        strokeWidth: 4,
        easing: 'easeInOut',
        color: lineColor,
        trailColor: 'transparent',
        trailWidth: 0,
        svgStyle: null,
        text: {
            value: '0',
        },
        step: function (state, circle) {
            var value = Math.round(circle.value() * 100);
            if (value === 0) {
                circle.setText('');
            } else {
                circle.setText(value + '%');
            }

        },
    });
    bar.text.style.color = textColor;
    bar.animate(percentage);
    element.children('.grey-circle').fadeIn();
};

var animateQuoteLine = function (element) {
    element.children('.animating').removeClass('animating');
};

var triggerAnimation = function (element) {
    'use strict';

    //get the desired animation class from the data attribute
    var animationClass = element.attr('data-animation');
    //add the animation classes to begin the animation
    element.addClass('animated ' + animationClass);
};

var progressHeroIntroAnimation = function () {
    $('.position-back').removeClass('position-back').addClass('hidden');
    $('.position-top').removeClass('position-top').addClass('position-back');
    $('.position-1').removeClass('position-1').addClass('position-top');
    $('.position-2').removeClass('position-2').addClass('position-1');
    $('.position-3').removeClass('position-3').addClass('position-2');
    $('.position-4').removeClass('position-4').addClass('position-3');

    if ($('#intro-hero.stage-1').length > 0) {
        $('#intro-hero.stage-1').removeClass('stage-1').addClass('stage-2');
    } else if ($('#intro-hero.stage-2').length > 0) {
        $('#intro-hero.stage-2').removeClass('stage-2').addClass('stage-3');
    } else if ($('#intro-hero.stage-3').length > 0) {
        $('#intro-hero.stage-3').removeClass('stage-3');
    }
};

var animateLogoColourChange = function () {
    $('.logo-part').addClass('displayed');
    $('.logo-part-1-pink').removeClass('animated fadeIn');

    setInterval(function () {
        if ($('.logo-part-1-pink').css('opacity') == 1) {
            $('.logo-part-1-pink').css({
                opacity: '0',
            });
            $('.logo-part-1-blue').css({
                opacity: '1',
            });
        } else if ($('.logo-part-1-pink').css('opacity') == 0) {
            $('.logo-part-1-pink').css({
                opacity: '1',
            });
            $('.logo-part-1-blue').css({
                opacity: '0',
            });
        }
    }, 500);
};

/**
 * @param cname
 * @param cvalue
 * @param exdays
 */
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = 'expires='+ d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

function getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

var animateHero = function () {
    'use strict';

    if (getCookie('seenintro') !== 'seen') {

        $('body').addClass('animating');
        $('#intro-hero').show();
        $('#intro-hero').addClass('animating');
        $('#intro-hero #logo-container').addClass('animating');

        $('#intro-hero .hero-intro-word-1').fadeIn(250).delay(1000).fadeOut(250, function () {
            progressHeroIntroAnimation();
        });

        //fade in + delay + fadeout + slide up
        //250 + 750 + 250 + 250 = 2500
        setTimeout(function () {
            $('#intro-hero .hero-intro-word-2').fadeIn(250).delay(1000).fadeOut(250, function () {
                progressHeroIntroAnimation();
            });
        }, 1500);

        setTimeout(function () {
            $('#intro-hero .hero-intro-word-3').fadeIn(250).delay(1000).fadeOut(250, function () {
                progressHeroIntroAnimation();
            });
        }, 3000);

        setTimeout(function () {
            progressHeroIntroAnimation();
            animateCompositeLogo();

            setTimeout(function () {
                setCookie('seenintro', 'seen', 365);
                animateLogoColourChange();
                $('#logo-text').fadeTo(500, 1);
                setTimeout(function () {
                    $('body').removeClass('animating');
                    $('#intro-hero').slideUp();
                }, 4000);
            }, 1500);

        }, 4500);
    }
};

//Takes 1.5 seconds total
var animateCompositeLogo = function () {
    $('#logo-part-3').show();
    triggerAnimation($('#logo-part-3'));
    setTimeout(function () {
        $('#logo-part-1').show();
        triggerAnimation($('#logo-part-1'));
        setTimeout(function () {
            $('#logo-part-2').show();
            triggerAnimation($('#logo-part-2'));
        }, 500);
    }, 500);
};

var activateSlider = function (classString, options) {
    $('.' + classString).each(function () {
        var element = $(this);
        var id = element.attr('data-block-id');

        if (options === undefined) {
            options = {};
        }

        var mergedOptions = $.extend({
            nextArrow: $('#right-control-' + id),
            prevArrow: $('#left-control-' + id),
            adaptiveHeight: true,
            accessibility: false,
        }, options);

        element.slick(mergedOptions);
    });
};

var positionCommunic8 = function () {
    var contactPanel = $('#contact-hover-panel');
    var right = 'calc(40px - ' + contactPanel.css('width');
    contactPanel.css('right', right);
};

var setupProgressCircles = function () {
    var progressCircles = $('.progress-circle');

    //add a random id to all of the progress circles
    progressCircles.each(function () {
        var element = $(this);

        var unique = false;
        while (unique === false) {
            //generate a random string
            var string = Math.random().toString(36).slice(2);
            //remove the numbers from it
            string = string.replace(/\d+/g, '');
            var id = '#' + string;

            if (string.length > 0) { //if the generated string is more than 0 characters
                if ($(id).length === 0) { //and an element with this id does not already exist on the page
                    unique = true;
                }
            }
        }
        //set the id of the element to this new string
        element.attr('id', string);
    });

    //Have to do this in here not css because the stroke width is set on the element so css is ignored
    progressCircles.hover(
        function () {
            $(this).children('svg').children('path').attr('stroke-width', 2);
        }, function () {
            $(this).children('svg').children('path').attr('stroke-width', 4);
        }
    );
};

var filterSliderForCareersSection = function (classString) {
    $(classString).each(function () {
        var slider = $(this);
        var slideClass = '.' + $('body').attr('class') + '-element-slide';
        slider.slick('slickUnfilter');
        slider.slick('slickFilter', slideClass);
    });
};

var filterSlidersForCareersSection = function () {
    var bodyElement = $('body');
    if (bodyElement.hasClass('career-management-1') ||
        bodyElement.hasClass('career-management-2') ||
        bodyElement.hasClass('career-management-3') ||
        bodyElement.hasClass('career-management-4')
    ) {
        filterSliderForCareersSection('.testimonials-slider');
        filterSliderForCareersSection('.projects-slider');
    }
};

var getURLParameter = function(name){
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results !== null) {
        return results[1] || 0;
    } else {
        return '';
    }
};

var filterCareerManagementContentIfControlsPresent = function () {
    if ($('.career-management-controls').length > 0) {
        var classToUse = 'career-management-1';
        var cm = decodeURIComponent(getURLParameter('cm'));
        if (cm !== '' && cm !== undefined) {
            classToUse = cm;
        }
        $('body').addClass(classToUse);
        $('.control-button').removeClass('active');
        $('.control-button.' + classToUse).addClass('active');
    }
};

var openTeamMembersBlockIfPresentAndTeamMemberInURL = function () {
    if ($('.team-members-block').length > 0) {
        var teamMember = decodeURIComponent(getURLParameter('team-member'));
        if (teamMember !== '' && teamMember !== undefined) {
            openTeamMemberDetails(teamMember);
        }
    }
};

var resizePanelContainers = function() {
    'use strict';
    $('.panel-container').each(function () {
        $(this).css('min-height', $(this).find('.panel').first().height());
    });
};

var openTeamMemberDetails = function (teamMemberID) {
    var popup = $('#team-member-popup-' + teamMemberID);
    popup.addClass('open');
    $('html, body').animate({
        scrollTop: popup.offset().top - 90,
    }, 1000);
};

var showMegaMenu = function () {
    var megamenu = $('.mega-menu');
    megamenu.fadeIn();
    megamenu.addClass('visible');
    $('#main-nav').addClass('mega-menu-open');
};

var hideMegaMenu = function () {
    var megamenu = $('.mega-menu');
    megamenu.fadeOut();
    megamenu.removeClass('visible');
    $('#main-nav').removeClass('mega-menu-open');
};

$(document).ready(function () {

    if ($('#intro-hero').length) {
        animateHero();
    }

    resizePanelContainers();
    setupProgressCircles();
    activateSlider('images-slider', {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });
    activateSlider('projects-slider', {
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });
    activateSlider('posts-slider', {
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });
    activateSlider('testimonials-slider');
    positionCommunic8();
    filterCareerManagementContentIfControlsPresent();
    filterSlidersForCareersSection();
    openTeamMembersBlockIfPresentAndTeamMemberInURL();
    $('.carousel').carousel({
        interval: 6000,
    });

    // After all the above is done we can safely show the html
    $('html').css('visibility', 'visible');

    $('.nav-link').hover(function () {
        showMegaMenu();
    }, function () {

    });

    $('#main-nav').hover(function () {

    }, function () {
        hideMegaMenu();
    });

    $('.navbar-toggler').click(function () {
        $('#main-nav').toggleClass('navbar-open');
    });

    $('#contact-hover-panel').hover(function () {
        $(this).addClass('open');
    }, function () {

    });

    $('.main-nav-link').hover(function () {
        var id = $(this).attr('data-page-id');
        $('.mega-menu .nav-item').addClass('hidden');
        $('.nav-item-' + id).removeClass('hidden');
    });

    $('#close-contact-hover-panel').click(function () {
        $('#contact-hover-panel').removeClass('open');
    });

    $('.control-button').click(function () {
        $('.control-button').removeClass('active');
        var careerControlClass = $(this).attr('data-career-control-class');
        $('.control-button.' + careerControlClass).addClass('active');
        var bodyElement = $('body');
        bodyElement.removeClass('career-management-1 career-management-2 career-management-3 career-management-4');
        bodyElement.addClass(careerControlClass);
        filterSlidersForCareersSection();
    });

    $('#client-case-studies').click(function () {
        if ($(this).hasClass('selected')) {
            showAllCaseStudies();
        } else {
            showClientCaseStudies();
        }
        pushAndPullCaseStudiesColumns();
    });

    $('#individual-case-studies').click(function () {
        if ($(this).hasClass('selected')) {
            showAllCaseStudies();
        } else {
            showIndividualCaseStudies();
        }
        pushAndPullCaseStudiesColumns();
    });

    $('.value-bubble:not(.center-bubble)').click(function () {
        $('.value-bubble.active').removeClass('active');
        $(this).addClass('active');
        $('.center-bubble .text').html($(this).attr('data-text'));
        $('.center-bubble').addClass('displaying-text');
    });

    $('.open-details-link').click(function () {
        var teamMemberID = $(this).attr('data-team-member-id');
        openTeamMemberDetails(teamMemberID);
    });

    $('.close-details-icon').click(function () {
        $('.popup').removeClass('open');
    });

    var moveContentAboveTeamMemberImage = function (teamMemberID) {
        var teamMemberImage = $('#team-member-image-' + teamMemberID);
        var teamMemberContent = $('#team-member-content-' + teamMemberID);
        teamMemberImage.removeClass('order-2').addClass('order-1');
        teamMemberContent.removeClass('order-1').addClass('order-2');
    };

    var moveContentBelowTeamMemberImage = function (teamMemberID) {
        var teamMemberImage = $('#team-member-image-' + teamMemberID);
        var teamMemberContent = $('#team-member-content-' + teamMemberID);
        teamMemberImage.removeClass('order-1').addClass('order-2');
        teamMemberContent.removeClass('order-2').addClass('order-1');
    };

    $('.overview-button').click(function () {
        var teamMemberID = $(this).attr('data-team-member-id');

        $(this).addClass('active');
        $('#team-member-overview-' + teamMemberID).addClass('active');

        $('#experience-button-' + teamMemberID).removeClass('active');
        $('#team-member-experience-' + teamMemberID).removeClass('active');
        $('#location-button-' + teamMemberID).removeClass('active');
        $('#team-member-location-' + teamMemberID).removeClass('active');
        moveContentAboveTeamMemberImage(teamMemberID);
    });

    $('.experience-button').click(function () {
        var teamMemberID = $(this).attr('data-team-member-id');

        $(this).addClass('active');
        $('#team-member-experience-' + teamMemberID).addClass('active');

        $('#overview-button-' + teamMemberID).removeClass('active');
        $('#team-member-overview-' + teamMemberID).removeClass('active');
        $('#location-button-' + teamMemberID).removeClass('active');
        $('#team-member-location-' + teamMemberID).removeClass('active');
        moveContentBelowTeamMemberImage(teamMemberID);
    });

    $('.location-button').click(function () {
        var teamMemberID = $(this).attr('data-team-member-id');

        $(this).addClass('active');
        $('#team-member-location-' + teamMemberID).addClass('active');

        $('#overview-button-' + teamMemberID).removeClass('active');
        $('#team-member-overview-' + teamMemberID).removeClass('active');
        $('#experience-button-' + teamMemberID).removeClass('active');
        $('#team-member-experience-' + teamMemberID).removeClass('active');
        moveContentBelowTeamMemberImage(teamMemberID);
    });

    $(window).scroll(function () {
        if ($(window).scrollTop() > 0) {
            $('#main-nav').addClass('scrolling');
        } else {
            $('#main-nav').removeClass('scrolling');
        }
    });

    //window and animation items
    var animation_elements = $('.animation-element');
    var web_window = $(window);

    //check to see if any animation containers are currently in view
    var checkIfInView = function () {
        //get current window information
        var window_height = web_window.height();
        var window_top_position = web_window.scrollTop();
        var window_bottom_position = (window_top_position + window_height);

        //iterate through elements to see if its in view
        $.each(animation_elements, function () {

            //get the elements information
            var element = $(this);
            var element_height = $(element).outerHeight();
            var element_top_position = $(element).offset().top;
            var element_bottom_position = (element_top_position + element_height);

            //check to see if this current container is visible (its viewable if it exists between the viewable space of the viewport)
            if ((element_bottom_position >= window_top_position) && (element_top_position <= window_bottom_position)) {

                triggerAnimation(element);

                if (element.hasClass('progress-circle')) {
                    animateProgressCircle(element.attr('id'));
                }

                if (element.hasClass('quote-line')) {
                    animateQuoteLine(element);
                }

                //remove the animation marker class so this element doesn't get picked up again
                element.removeClass('animation-element');
                //refresh the list of elements left to animate
                animation_elements = $.find('.animation-element');
            }
        });
    };

    $(window).on('resize', function () {
        resizePanelContainers();
    });

    //on or scroll, detect elements in view
    $(window).on('scroll resize', function () {
        checkIfInView();
        hideMegaMenu();
    });

    //trigger our scroll event on initial load
    $(window).trigger('scroll');
});